import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

const MatchPasswordsValidator = (fieldOne: string, fieldTwo: string): ValidatorFn => (form: FormGroup): ValidationErrors | null => {
	let response: { [key: string]: any } = null;

	if (form.get(fieldOne).value !== form.get(fieldTwo).value) {
		response = {
			matchPasswords: 'Passwords do not match'
		};
	}

	return response;
};

export { MatchPasswordsValidator };

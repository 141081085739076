var tempAdjacencyGraphs = {
  "azerty": {
    "0": ["ç9", null, null, ")°", "pP", "oO"],
    "1": ["²~", null, null, "é2", "aA", null],
    "2": ["&1", null, null, "\"3", "zZ", "aA"],
    "3": ["é2", null, null, "'4", "eE", "zZ"],
    "4": ["\"3", null, null, "(5", "rR", "eE"],
    "5": ["'4", null, null, "-6", "tT", "rR"],
    "6": ["(5", null, null, "è7", "yY", "tT"],
    "7": ["-6", null, null, "_8", "uU", "yY"],
    "8": ["è7", null, null, "ç9", "iI", "uU"],
    "9": ["_8", null, null, "à0", "oO", "iI"],
    "²": [null, null, null, "&1", null, null],
    "~": [null, null, null, "&1", null, null],
    "&": ["²~", null, null, "é2", "aA", null],
    "é": ["&1", null, null, "\"3", "zZ", "aA"],
    "\"": ["pP", ")°", "+=", "$£", "ù%", "mM"],
    "'": ["\"3", null, null, "(5", "rR", "eE"],
    "(": ["'4", null, null, "-6", "tT", "rR"],
    "-": ["(5", null, null, "è7", "yY", "tT"],
    "è": ["-6", null, null, "_8", "uU", "yY"],
    "_": ["è7", null, null, "ç9", "iI", "uU"],
    "ç": ["_8", null, null, "à0", "oO", "iI"],
    "à": ["ç9", null, null, ")°", "pP", "oO"],
    ")": ["à0", null, null, "+=", "^\"", "pP"],
    "°": ["à0", null, null, "+=", "^\"", "pP"],
    "+": [")°", null, null, null, "$£", "^\""],
    "=": [")°", null, null, null, "$£", "^\""],
    "a": [null, "&1", "é2", "zZ", "qQ", null],
    "A": [null, "&1", "é2", "zZ", "qQ", null],
    "z": ["aA", "é2", "\"3", "eE", "sS", "qQ"],
    "Z": ["aA", "é2", "\"3", "eE", "sS", "qQ"],
    "e": ["zZ", "\"3", "'4", "rR", "dD", "sS"],
    "E": ["zZ", "\"3", "'4", "rR", "dD", "sS"],
    "r": ["eE", "'4", "(5", "tT", "fF", "dD"],
    "R": ["eE", "'4", "(5", "tT", "fF", "dD"],
    "t": ["rR", "(5", "-6", "yY", "gG", "fF"],
    "T": ["rR", "(5", "-6", "yY", "gG", "fF"],
    "y": ["tT", "-6", "è7", "uU", "hH", "gG"],
    "Y": ["tT", "-6", "è7", "uU", "hH", "gG"],
    "u": ["yY", "è7", "_8", "iI", "jJ", "hH"],
    "U": ["yY", "è7", "_8", "iI", "jJ", "hH"],
    "i": ["uU", "_8", "ç9", "oO", "kK", "jJ"],
    "I": ["uU", "_8", "ç9", "oO", "kK", "jJ"],
    "o": ["iI", "ç9", "à0", "pP", "lL", "kK"],
    "O": ["iI", "ç9", "à0", "pP", "lL", "kK"],
    "p": ["oO", "à0", ")°", "^\"", "mM", "lL"],
    "P": ["oO", "à0", ")°", "^\"", "mM", "lL"],
    "^": ["pP", ")°", "+=", "$£", "ù%", "mM"],
    "$": ["^\"", "+=", null, null, "*µ", "ù%"],
    "£": ["^\"", "+=", null, null, "*µ", "ù%"],
    "q": [null, "aA", "zZ", "sS", "wW", "<>"],
    "Q": [null, "aA", "zZ", "sS", "wW", "<>"],
    "s": ["qQ", "zZ", "eE", "dD", "xX", "wW"],
    "S": ["qQ", "zZ", "eE", "dD", "xX", "wW"],
    "d": ["sS", "eE", "rR", "fF", "cC", "xX"],
    "D": ["sS", "eE", "rR", "fF", "cC", "xX"],
    "f": ["dD", "rR", "tT", "gG", "vV", "cC"],
    "F": ["dD", "rR", "tT", "gG", "vV", "cC"],
    "g": ["fF", "tT", "yY", "hH", "bB", "vV"],
    "G": ["fF", "tT", "yY", "hH", "bB", "vV"],
    "h": ["gG", "yY", "uU", "jJ", "nN", "bB"],
    "H": ["gG", "yY", "uU", "jJ", "nN", "bB"],
    "j": ["hH", "uU", "iI", "kK", ",?", "nN"],
    "J": ["hH", "uU", "iI", "kK", ",?", "nN"],
    "k": ["jJ", "iI", "oO", "lL", ";.", ",?"],
    "K": ["jJ", "iI", "oO", "lL", ";.", ",?"],
    "l": ["kK", "oO", "pP", "mM", ":/", ";."],
    "L": ["kK", "oO", "pP", "mM", ":/", ";."],
    "m": ["lL", "pP", "^\"", "ù%", "!§", ":/"],
    "M": ["lL", "pP", "^\"", "ù%", "!§", ":/"],
    "ù": ["mM", "^\"", "$£", "*µ", null, "!§"],
    "%": ["mM", "^\"", "$£", "*µ", null, "!§"],
    "*": ["ù%", "$£", null, null, null, null],
    "µ": ["ù%", "$£", null, null, null, null],
    "<": [null, null, "qQ", "wW", null, null],
    ">": [null, null, "qQ", "wW", null, null],
    "w": ["<>", "qQ", "sS", "xX", null, null],
    "W": ["<>", "qQ", "sS", "xX", null, null],
    "x": ["wW", "sS", "dD", "cC", null, null],
    "X": ["wW", "sS", "dD", "cC", null, null],
    "c": ["xX", "dD", "fF", "vV", null, null],
    "C": ["xX", "dD", "fF", "vV", null, null],
    "v": ["cC", "fF", "gG", "bB", null, null],
    "V": ["cC", "fF", "gG", "bB", null, null],
    "b": ["vV", "gG", "hH", "nN", null, null],
    "B": ["vV", "gG", "hH", "nN", null, null],
    "n": ["bB", "hH", "jJ", ",?", null, null],
    "N": ["bB", "hH", "jJ", ",?", null, null],
    ",": ["nN", "jJ", "kK", ";.", null, null],
    "?": ["nN", "jJ", "kK", ";.", null, null],
    ";": [",?", "kK", "lL", ":/", null, null],
    ".": [",?", "kK", "lL", ":/", null, null],
    ":": [";.", "lL", "mM", "!§", null, null],
    "/": [";.", "lL", "mM", "!§", null, null],
    "!": [":/", "mM", "ù%", null, null, null],
    "§": [":/", "mM", "ù%", null, null, null]
  },
  "dvorak": {
    "0": ["9(", null, null, "[{", "lL", "rR"],
    "1": ["`~", null, null, "2@", "'\"", null],
    "2": ["1!", null, null, "3#", ",<", "'\""],
    "3": ["2@", null, null, "4$", ".>", ",<"],
    "4": ["3#", null, null, "5%", "pP", ".>"],
    "5": ["4$", null, null, "6^", "yY", "pP"],
    "6": ["5%", null, null, "7&", "fF", "yY"],
    "7": ["6^", null, null, "8*", "gG", "fF"],
    "8": ["7&", null, null, "9(", "cC", "gG"],
    "9": ["8*", null, null, "0)", "rR", "cC"],
    "`": [null, null, null, "1!", null, null],
    "~": [null, null, null, "1!", null, null],
    "!": ["`~", null, null, "2@", "'\"", null],
    "@": ["1!", null, null, "3#", ",<", "'\""],
    "#": ["2@", null, null, "4$", ".>", ",<"],
    "$": ["3#", null, null, "5%", "pP", ".>"],
    "%": ["4$", null, null, "6^", "yY", "pP"],
    "^": ["5%", null, null, "7&", "fF", "yY"],
    "&": ["6^", null, null, "8*", "gG", "fF"],
    "*": ["7&", null, null, "9(", "cC", "gG"],
    "(": ["8*", null, null, "0)", "rR", "cC"],
    ")": ["9(", null, null, "[{", "lL", "rR"],
    "[": ["0)", null, null, "]}", "/?", "lL"],
    "{": ["0)", null, null, "]}", "/?", "lL"],
    "]": ["[{", null, null, null, "=+", "/?"],
    "}": ["[{", null, null, null, "=+", "/?"],
    "'": [null, "1!", "2@", ",<", "aA", null],
    "\"": [null, "1!", "2@", ",<", "aA", null],
    ",": ["'\"", "2@", "3#", ".>", "oO", "aA"],
    "<": ["'\"", "2@", "3#", ".>", "oO", "aA"],
    ".": [",<", "3#", "4$", "pP", "eE", "oO"],
    ">": [",<", "3#", "4$", "pP", "eE", "oO"],
    "p": [".>", "4$", "5%", "yY", "uU", "eE"],
    "P": [".>", "4$", "5%", "yY", "uU", "eE"],
    "y": ["pP", "5%", "6^", "fF", "iI", "uU"],
    "Y": ["pP", "5%", "6^", "fF", "iI", "uU"],
    "f": ["yY", "6^", "7&", "gG", "dD", "iI"],
    "F": ["yY", "6^", "7&", "gG", "dD", "iI"],
    "g": ["fF", "7&", "8*", "cC", "hH", "dD"],
    "G": ["fF", "7&", "8*", "cC", "hH", "dD"],
    "c": ["gG", "8*", "9(", "rR", "tT", "hH"],
    "C": ["gG", "8*", "9(", "rR", "tT", "hH"],
    "r": ["cC", "9(", "0)", "lL", "nN", "tT"],
    "R": ["cC", "9(", "0)", "lL", "nN", "tT"],
    "l": ["rR", "0)", "[{", "/?", "sS", "nN"],
    "L": ["rR", "0)", "[{", "/?", "sS", "nN"],
    "/": ["lL", "[{", "]}", "=+", "-_", "sS"],
    "?": ["lL", "[{", "]}", "=+", "-_", "sS"],
    "=": ["/?", "]}", null, "\\|", null, "-_"],
    "+": ["/?", "]}", null, "\\|", null, "-_"],
    "\\": ["=+", null, null, null, null, null],
    "|": ["=+", null, null, null, null, null],
    "a": [null, "'\"", ",<", "oO", ";:", null],
    "A": [null, "'\"", ",<", "oO", ";:", null],
    "o": ["aA", ",<", ".>", "eE", "qQ", ";:"],
    "O": ["aA", ",<", ".>", "eE", "qQ", ";:"],
    "e": ["oO", ".>", "pP", "uU", "jJ", "qQ"],
    "E": ["oO", ".>", "pP", "uU", "jJ", "qQ"],
    "u": ["eE", "pP", "yY", "iI", "kK", "jJ"],
    "U": ["eE", "pP", "yY", "iI", "kK", "jJ"],
    "i": ["uU", "yY", "fF", "dD", "xX", "kK"],
    "I": ["uU", "yY", "fF", "dD", "xX", "kK"],
    "d": ["iI", "fF", "gG", "hH", "bB", "xX"],
    "D": ["iI", "fF", "gG", "hH", "bB", "xX"],
    "h": ["dD", "gG", "cC", "tT", "mM", "bB"],
    "H": ["dD", "gG", "cC", "tT", "mM", "bB"],
    "t": ["hH", "cC", "rR", "nN", "wW", "mM"],
    "T": ["hH", "cC", "rR", "nN", "wW", "mM"],
    "n": ["tT", "rR", "lL", "sS", "vV", "wW"],
    "N": ["tT", "rR", "lL", "sS", "vV", "wW"],
    "s": ["nN", "lL", "/?", "-_", "zZ", "vV"],
    "S": ["nN", "lL", "/?", "-_", "zZ", "vV"],
    "-": ["sS", "/?", "=+", null, null, "zZ"],
    "_": ["sS", "/?", "=+", null, null, "zZ"],
    ";": [null, "aA", "oO", "qQ", null, null],
    ":": [null, "aA", "oO", "qQ", null, null],
    "q": [";:", "oO", "eE", "jJ", null, null],
    "Q": [";:", "oO", "eE", "jJ", null, null],
    "j": ["qQ", "eE", "uU", "kK", null, null],
    "J": ["qQ", "eE", "uU", "kK", null, null],
    "k": ["jJ", "uU", "iI", "xX", null, null],
    "K": ["jJ", "uU", "iI", "xX", null, null],
    "x": ["kK", "iI", "dD", "bB", null, null],
    "X": ["kK", "iI", "dD", "bB", null, null],
    "b": ["xX", "dD", "hH", "mM", null, null],
    "B": ["xX", "dD", "hH", "mM", null, null],
    "m": ["bB", "hH", "tT", "wW", null, null],
    "M": ["bB", "hH", "tT", "wW", null, null],
    "w": ["mM", "tT", "nN", "vV", null, null],
    "W": ["mM", "tT", "nN", "vV", null, null],
    "v": ["wW", "nN", "sS", "zZ", null, null],
    "V": ["wW", "nN", "sS", "zZ", null, null],
    "z": ["vV", "sS", "-_", null, null, null],
    "Z": ["vV", "sS", "-_", null, null, null]
  },
  "keypad": {
    "0": [null, "1", "2", "3", ".", null, null, null],
    "1": [null, null, "4", "5", "2", "0", null, null],
    "2": ["1", "4", "5", "6", "3", ".", "0", null],
    "3": ["2", "5", "6", null, null, null, ".", "0"],
    "4": [null, null, "7", "8", "5", "2", "1", null],
    "5": ["4", "7", "8", "9", "6", "3", "2", "1"],
    "6": ["5", "8", "9", "+", null, null, "3", "2"],
    "7": [null, null, null, "/", "8", "5", "4", null],
    "8": ["7", null, "/", "*", "9", "6", "5", "4"],
    "9": ["8", "/", "*", "-", "+", null, "6", "5"],
    "/": [null, null, null, null, "*", "9", "8", "7"],
    "*": ["/", null, null, null, "-", "+", "9", "8"],
    "-": ["*", null, null, null, null, null, "+", "9"],
    "+": ["9", "*", "-", null, null, null, null, "6"],
    ".": ["0", "2", "3", null, null, null, null, null]
  },
  "keypadMac": {
    "0": [null, "1", "2", "3", ".", null, null, null],
    "1": [null, null, "4", "5", "2", "0", null, null],
    "2": ["1", "4", "5", "6", "3", ".", "0", null],
    "3": ["2", "5", "6", "+", null, null, ".", "0"],
    "4": [null, null, "7", "8", "5", "2", "1", null],
    "5": ["4", "7", "8", "9", "6", "3", "2", "1"],
    "6": ["5", "8", "9", "-", "+", null, "3", "2"],
    "7": [null, null, null, "=", "8", "5", "4", null],
    "8": ["7", null, "=", "/", "9", "6", "5", "4"],
    "9": ["8", "=", "/", "*", "-", "+", "6", "5"],
    "=": [null, null, null, null, "/", "9", "8", "7"],
    "/": ["=", null, null, null, "*", "-", "9", "8"],
    "*": ["/", null, null, null, null, null, "-", "9"],
    "-": ["9", "/", "*", null, null, null, "+", "6"],
    "+": ["6", "9", "-", null, null, null, null, "3"],
    ".": ["0", "2", "3", null, null, null, null, null]
  },
  "qwerty": {
    "0": ["9(", null, null, "-_", "pP", "oO"],
    "1": ["`~", null, null, "2@", "qQ", null],
    "2": ["1!", null, null, "3#", "wW", "qQ"],
    "3": ["2@", null, null, "4$", "eE", "wW"],
    "4": ["3#", null, null, "5%", "rR", "eE"],
    "5": ["4$", null, null, "6^", "tT", "rR"],
    "6": ["5%", null, null, "7&", "yY", "tT"],
    "7": ["6^", null, null, "8*", "uU", "yY"],
    "8": ["7&", null, null, "9(", "iI", "uU"],
    "9": ["8*", null, null, "0)", "oO", "iI"],
    "`": [null, null, null, "1!", null, null],
    "~": [null, null, null, "1!", null, null],
    "!": ["`~", null, null, "2@", "qQ", null],
    "@": ["1!", null, null, "3#", "wW", "qQ"],
    "#": ["2@", null, null, "4$", "eE", "wW"],
    "$": ["3#", null, null, "5%", "rR", "eE"],
    "%": ["4$", null, null, "6^", "tT", "rR"],
    "^": ["5%", null, null, "7&", "yY", "tT"],
    "&": ["6^", null, null, "8*", "uU", "yY"],
    "*": ["7&", null, null, "9(", "iI", "uU"],
    "(": ["8*", null, null, "0)", "oO", "iI"],
    ")": ["9(", null, null, "-_", "pP", "oO"],
    "-": ["0)", null, null, "=+", "[{", "pP"],
    "_": ["0)", null, null, "=+", "[{", "pP"],
    "=": ["-_", null, null, null, "]}", "[{"],
    "+": ["-_", null, null, null, "]}", "[{"],
    "q": [null, "1!", "2@", "wW", "aA", null],
    "Q": [null, "1!", "2@", "wW", "aA", null],
    "w": ["qQ", "2@", "3#", "eE", "sS", "aA"],
    "W": ["qQ", "2@", "3#", "eE", "sS", "aA"],
    "e": ["wW", "3#", "4$", "rR", "dD", "sS"],
    "E": ["wW", "3#", "4$", "rR", "dD", "sS"],
    "r": ["eE", "4$", "5%", "tT", "fF", "dD"],
    "R": ["eE", "4$", "5%", "tT", "fF", "dD"],
    "t": ["rR", "5%", "6^", "yY", "gG", "fF"],
    "T": ["rR", "5%", "6^", "yY", "gG", "fF"],
    "y": ["tT", "6^", "7&", "uU", "hH", "gG"],
    "Y": ["tT", "6^", "7&", "uU", "hH", "gG"],
    "u": ["yY", "7&", "8*", "iI", "jJ", "hH"],
    "U": ["yY", "7&", "8*", "iI", "jJ", "hH"],
    "i": ["uU", "8*", "9(", "oO", "kK", "jJ"],
    "I": ["uU", "8*", "9(", "oO", "kK", "jJ"],
    "o": ["iI", "9(", "0)", "pP", "lL", "kK"],
    "O": ["iI", "9(", "0)", "pP", "lL", "kK"],
    "p": ["oO", "0)", "-_", "[{", ";:", "lL"],
    "P": ["oO", "0)", "-_", "[{", ";:", "lL"],
    "[": ["pP", "-_", "=+", "]}", "'\"", ";:"],
    "{": ["pP", "-_", "=+", "]}", "'\"", ";:"],
    "]": ["[{", "=+", null, "\\|", null, "'\""],
    "}": ["[{", "=+", null, "\\|", null, "'\""],
    "\\": ["]}", null, null, null, null, null],
    "|": ["]}", null, null, null, null, null],
    "a": [null, "qQ", "wW", "sS", "zZ", null],
    "A": [null, "qQ", "wW", "sS", "zZ", null],
    "s": ["aA", "wW", "eE", "dD", "xX", "zZ"],
    "S": ["aA", "wW", "eE", "dD", "xX", "zZ"],
    "d": ["sS", "eE", "rR", "fF", "cC", "xX"],
    "D": ["sS", "eE", "rR", "fF", "cC", "xX"],
    "f": ["dD", "rR", "tT", "gG", "vV", "cC"],
    "F": ["dD", "rR", "tT", "gG", "vV", "cC"],
    "g": ["fF", "tT", "yY", "hH", "bB", "vV"],
    "G": ["fF", "tT", "yY", "hH", "bB", "vV"],
    "h": ["gG", "yY", "uU", "jJ", "nN", "bB"],
    "H": ["gG", "yY", "uU", "jJ", "nN", "bB"],
    "j": ["hH", "uU", "iI", "kK", "mM", "nN"],
    "J": ["hH", "uU", "iI", "kK", "mM", "nN"],
    "k": ["jJ", "iI", "oO", "lL", ",<", "mM"],
    "K": ["jJ", "iI", "oO", "lL", ",<", "mM"],
    "l": ["kK", "oO", "pP", ";:", ".>", ",<"],
    "L": ["kK", "oO", "pP", ";:", ".>", ",<"],
    ";": ["lL", "pP", "[{", "'\"", "/?", ".>"],
    ":": ["lL", "pP", "[{", "'\"", "/?", ".>"],
    "'": [";:", "[{", "]}", null, null, "/?"],
    "\"": [";:", "[{", "]}", null, null, "/?"],
    "z": [null, "aA", "sS", "xX", null, null],
    "Z": [null, "aA", "sS", "xX", null, null],
    "x": ["zZ", "sS", "dD", "cC", null, null],
    "X": ["zZ", "sS", "dD", "cC", null, null],
    "c": ["xX", "dD", "fF", "vV", null, null],
    "C": ["xX", "dD", "fF", "vV", null, null],
    "v": ["cC", "fF", "gG", "bB", null, null],
    "V": ["cC", "fF", "gG", "bB", null, null],
    "b": ["vV", "gG", "hH", "nN", null, null],
    "B": ["vV", "gG", "hH", "nN", null, null],
    "n": ["bB", "hH", "jJ", "mM", null, null],
    "N": ["bB", "hH", "jJ", "mM", null, null],
    "m": ["nN", "jJ", "kK", ",<", null, null],
    "M": ["nN", "jJ", "kK", ",<", null, null],
    ",": ["mM", "kK", "lL", ".>", null, null],
    "<": ["mM", "kK", "lL", ".>", null, null],
    ".": [",<", "lL", ";:", "/?", null, null],
    ">": [",<", "lL", ";:", "/?", null, null],
    "/": [".>", ";:", "'\"", null, null, null],
    "?": [".>", ";:", "'\"", null, null, null]
  },
  "qwertz": {
    "0": ["9)", null, null, "ß?", "pP", "oO"],
    "1": ["^°", null, null, "2\"", "qQ", null],
    "2": ["1!", null, null, "3§", "wW", "qQ"],
    "3": ["2\"", null, null, "4$", "eE", "wW"],
    "4": ["3§", null, null, "5%", "rR", "eE"],
    "5": ["4$", null, null, "6&", "tT", "rR"],
    "6": ["5%", null, null, "7/", "zZ", "tT"],
    "7": ["6&", null, null, "8(", "uU", "zZ"],
    "8": ["7/", null, null, "9)", "iI", "uU"],
    "9": ["8(", null, null, "0=", "oO", "iI"],
    "^": [null, null, null, "1!", null, null],
    "°": [null, null, null, "1!", null, null],
    "!": ["^°", null, null, "2\"", "qQ", null],
    "\"": ["1!", null, null, "3§", "wW", "qQ"],
    "§": ["2\"", null, null, "4$", "eE", "wW"],
    "$": ["3§", null, null, "5%", "rR", "eE"],
    "%": ["4$", null, null, "6&", "tT", "rR"],
    "&": ["5%", null, null, "7/", "zZ", "tT"],
    "/": ["6&", null, null, "8(", "uU", "zZ"],
    "(": ["7/", null, null, "9)", "iI", "uU"],
    ")": ["8(", null, null, "0=", "oO", "iI"],
    "=": ["9)", null, null, "ß?", "pP", "oO"],
    "ß": ["0=", null, null, "´`", "üÜ", "pP"],
    "?": ["0=", null, null, "´`", "üÜ", "pP"],
    "´": ["ß?", null, null, null, "+*", "üÜ"],
    "`": ["ß?", null, null, null, "+*", "üÜ"],
    "q": [null, "1!", "2\"", "wW", "aA", null],
    "Q": [null, "1!", "2\"", "wW", "aA", null],
    "w": ["qQ", "2\"", "3§", "eE", "sS", "aA"],
    "W": ["qQ", "2\"", "3§", "eE", "sS", "aA"],
    "e": ["wW", "3§", "4$", "rR", "dD", "sS"],
    "E": ["wW", "3§", "4$", "rR", "dD", "sS"],
    "r": ["eE", "4$", "5%", "tT", "fF", "dD"],
    "R": ["eE", "4$", "5%", "tT", "fF", "dD"],
    "t": ["rR", "5%", "6&", "zZ", "gG", "fF"],
    "T": ["rR", "5%", "6&", "zZ", "gG", "fF"],
    "z": ["tT", "6&", "7/", "uU", "hH", "gG"],
    "Z": ["tT", "6&", "7/", "uU", "hH", "gG"],
    "u": ["zZ", "7/", "8(", "iI", "jJ", "hH"],
    "U": ["zZ", "7/", "8(", "iI", "jJ", "hH"],
    "i": ["uU", "8(", "9)", "oO", "kK", "jJ"],
    "I": ["uU", "8(", "9)", "oO", "kK", "jJ"],
    "o": ["iI", "9)", "0=", "pP", "lL", "kK"],
    "O": ["iI", "9)", "0=", "pP", "lL", "kK"],
    "p": ["oO", "0=", "ß?", "üÜ", "öÖ", "lL"],
    "P": ["oO", "0=", "ß?", "üÜ", "öÖ", "lL"],
    "ü": ["pP", "ß?", "´`", "+*", "äÄ", "öÖ"],
    "Ü": ["pP", "ß?", "´`", "+*", "äÄ", "öÖ"],
    "+": ["üÜ", "´`", null, null, "#'", "äÄ"],
    "*": ["üÜ", "´`", null, null, "#'", "äÄ"],
    "a": [null, "qQ", "wW", "sS", "yY", "<>"],
    "A": [null, "qQ", "wW", "sS", "yY", "<>"],
    "s": ["aA", "wW", "eE", "dD", "xX", "yY"],
    "S": ["aA", "wW", "eE", "dD", "xX", "yY"],
    "d": ["sS", "eE", "rR", "fF", "cC", "xX"],
    "D": ["sS", "eE", "rR", "fF", "cC", "xX"],
    "f": ["dD", "rR", "tT", "gG", "vV", "cC"],
    "F": ["dD", "rR", "tT", "gG", "vV", "cC"],
    "g": ["fF", "tT", "zZ", "hH", "bB", "vV"],
    "G": ["fF", "tT", "zZ", "hH", "bB", "vV"],
    "h": ["gG", "zZ", "uU", "jJ", "nN", "bB"],
    "H": ["gG", "zZ", "uU", "jJ", "nN", "bB"],
    "j": ["hH", "uU", "iI", "kK", "mM", "nN"],
    "J": ["hH", "uU", "iI", "kK", "mM", "nN"],
    "k": ["jJ", "iI", "oO", "lL", ",;", "mM"],
    "K": ["jJ", "iI", "oO", "lL", ",;", "mM"],
    "l": ["kK", "oO", "pP", "öÖ", ".:", ",;"],
    "L": ["kK", "oO", "pP", "öÖ", ".:", ",;"],
    "ö": ["lL", "pP", "üÜ", "äÄ", "-_", ".:"],
    "Ö": ["lL", "pP", "üÜ", "äÄ", "-_", ".:"],
    "ä": ["öÖ", "üÜ", "+*", "#'", null, "-_"],
    "Ä": ["öÖ", "üÜ", "+*", "#'", null, "-_"],
    "#": ["äÄ", "+*", null, null, null, null],
    "'": ["äÄ", "+*", null, null, null, null],
    "<": [null, null, "aA", "yY", null, null],
    ">": [null, null, "aA", "yY", null, null],
    "y": ["<>", "aA", "sS", "xX", null, null],
    "Y": ["<>", "aA", "sS", "xX", null, null],
    "x": ["yY", "sS", "dD", "cC", null, null],
    "X": ["yY", "sS", "dD", "cC", null, null],
    "c": ["xX", "dD", "fF", "vV", null, null],
    "C": ["xX", "dD", "fF", "vV", null, null],
    "v": ["cC", "fF", "gG", "bB", null, null],
    "V": ["cC", "fF", "gG", "bB", null, null],
    "b": ["vV", "gG", "hH", "nN", null, null],
    "B": ["vV", "gG", "hH", "nN", null, null],
    "n": ["bB", "hH", "jJ", "mM", null, null],
    "N": ["bB", "hH", "jJ", "mM", null, null],
    "m": ["nN", "jJ", "kK", ",;", null, null],
    "M": ["nN", "jJ", "kK", ",;", null, null],
    ",": ["mM", "kK", "lL", ".:", null, null],
    ";": ["mM", "kK", "lL", ".:", null, null],
    ".": [",;", "lL", "öÖ", "-_", null, null],
    ":": [",;", "lL", "öÖ", "-_", null, null],
    "-": [".:", "öÖ", "äÄ", null, null, null],
    "_": [".:", "öÖ", "äÄ", null, null, null]
  }
};
export { tempAdjacencyGraphs as default };

import commonWords from './commonWords.json.esm.js';
import firstnames from './firstnames.json.esm.js';
import lastnames from './lastnames.json.esm.js';
import wikipedia from './wikipedia.json.esm.js';
export { default as translations } from './translations.esm.js';

// This file is auto generated by data-scripts/_helpers/runtime.ts
const dictionary = {
  commonWords,
  firstnames,
  lastnames,
  wikipedia
};
export { dictionary };

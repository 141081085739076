import { AbstractControl, ValidatorFn } from '@angular/forms';
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import { FeedbackType } from '@zxcvbn-ts/core/dist/types';
import { dictionary as dictionaryCommon, adjacencyGraphs as adjacencyGraphsCommon } from '@zxcvbn-ts/language-common';
import { dictionary as dictionaryEn, translations as translationsEn } from '@zxcvbn-ts/language-en';

interface ZxcvbnValidator {
	zxcvbn: FeedbackType;
}

const options = {
	translations: translationsEn,
	dictionary: {
		...dictionaryCommon,
		...dictionaryEn,
	},
	// The next line is now recommended to get a good scoring.
	graphs: adjacencyGraphsCommon,
};

zxcvbnOptions.setOptions(options);

export const zxcvbnValidator = (minStrength: number): ValidatorFn => (control: AbstractControl): ZxcvbnValidator | null => {
	let response: ZxcvbnValidator | null = null;
	const passwordString = control.value as string;

	if (passwordString?.length > 0) {
		const result = zxcvbn(passwordString);

		if (result.score < minStrength) {
			response = {
				zxcvbn: result.feedback
			};
		}
	}

	return response;
};
